/*
 * @Author: Libra
 * @Date: 2022-04-06 13:42:51
 * @LastEditTime: 2022-09-14 11:28:01
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/ui/index.ts
 */
import {
  create,
  NButton,
  NConfigProvider,
  NLayout,
  NSpace,
  NLayoutContent,
  NLayoutHeader,
  NLayoutFooter,
  NModal,
  NCard,
  NSelect,
  NSteps,
  NStep,
  NSpin,
  NForm,
  NFormItem,
  NFormItemGi,
  NInput,
  NInputNumber,
  NPopover,
  NCheckbox,
  NUpload,
  NEllipsis,
  NSlider,
  NGrid,
  NTooltip,
  NTab,
  NTabs,
  NTabPane,
} from "naive-ui";

const naive = create({
  components: [
    NButton,
    NConfigProvider,
    NLayout,
    NSpace,
    NLayoutContent,
    NLayoutHeader,
    NLayoutFooter,
    NModal,
    NCard,
    NSelect,
    NSteps,
    NStep,
    NSpin,
    NForm,
    NFormItem,
    NFormItemGi,
    NInput,
    NInputNumber,
    NPopover,
    NCheckbox,
    NUpload,
    NEllipsis,
    NSlider,
    NGrid,
    NTooltip,
    NTab,
    NTabs,
    NTabPane,
  ],
});

export default naive;
