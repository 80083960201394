/*
 * @Author: Libra
 * @Date: 2022-05-07 16:31:36
 * @LastEditTime: 2022-09-14 10:37:15
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/zh/identity.ts
 */
const identity = {
  authentication: "身份验证",
  authTip: "请用手机微信扫码，核验考生身份",
  name: "姓名",
  nameTip: "请输入姓名",
  identity: "身份证号",
  identityTip: "请输入身份证号",
  mobile: "手机号",
  mobileTip: "请输入手机号",
  email: "邮箱",
  emailTip: "请输入邮箱",
  resume: "简历",
  uploadResume: "上传简历",
  faceRecognition: "开始验证",
  next: "下一步",
  basicInfo: "基本信息",
  basicTipCan: "面试需要验证身份，请准确填写信息",
  basicTipExa: "鉴于保密原因，联系方式如果是虚拟的，可忽略",
  result: "结果",
  success: "成功",
  fail: "失败",
  pre: "上一步",
  snap: "去拍照",
  reason: "原因",
  remain: "剩余",
  time: "次",
  skip: "跳过",
  wechatScan: "手机微信扫码",
  getVerifyResult: "获取验证结果",
  mobileDetect: "手机监控",
  mobileDetectTip: "请用手机微信扫码，开启手机监控",
  mobileWechatScan: "手机微信扫码",
  putPhone: "竖立摆放手机",
  startDetect: "开启视频监控",
  scanFault: "扫码遇故障？",
  putPhoneRight: "电脑正侧方放置手机",
  confirmRecord: "确认是否开始录制",
  scanAndNext: "已扫码，下一步",
  faultTip:
    "扫码遇到问题怎么办？<br />1、请检查你的手机网络连接是否正常；<br />2、请更新手机微信APP至最新版，然后重新扫码；<br />3、手机在面试中需一直打开小程序，不要跳出监控页面，建议插上手机电源。",
  interviewNotice: "面试须知",
  interviewNoticeTip: "面试须知如下",
  agree: "我已阅读上述规定，我承诺遵守相关规定，如有违规行为，本人承担一切后果",
  startInterview: "进入面试",
  qrFailure: "二维码失效",
  refresh: "刷新",
  useCamera: "请允许使用摄像头",
  openCamera: "点击浏览器右上角，开启摄像头",
  refreshPage: "设备开启后，刷新页面方可生效",
  know: "我知道了",
  cameraSnap: "摄像头拍照",
  cameraSnapTip: "请自拍头像，您的所有个人信息将会严格保密",
  reSnap: "重新拍照",
  snapAndNext: "已拍照，下一步",
  goSnap: "拍照",
  enterWaitingRoom: "进入等候间",
  enterInterviewRoom: "进入面试间",
};
export default identity;
