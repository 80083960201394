/*
 * @Author: Libra
 * @Date: 2022-04-19 10:30:06
 * @LastEditTime: 2022-05-07 16:32:31
 * @LastEditors: Libra
 * @Description: 中文
 * @FilePath: /stone-interview-ui/src/locales/zh-CN.ts
 */
import test from "./zh/test";
import device from "./zh/device";
import component from "./zh/component";
import identity from "./zh/identity";
export default {
  test,
  device,
  component,
  identity,
};
