/*
 * @Author: Libra
 * @Date: 2022-04-24 16:04:40
 * @LastEditTime: 2022-09-07 15:14:59
 * @LastEditors: Libra
 * @Description:设备检测
 * @FilePath: /stone-interview-ui/src/locales/zh/device.ts
 */
const device = {
  deviceConnect: "设备连接",
  cameraDetect: "摄像头检测",
  microphoneDetect: "麦克风检测",
  speakerDetect: "扬声器检测",
  speakerTips: "请点击上方播放按钮，打开电脑声音",
  camerahint: "是否可以清楚的看到自己?",
  sayHello: "对着麦克风说 哈喽 试试~",
  microphonehint: "是否可以看到音量图标跳动？",
  subtitle: "设备检测前请确认设备连接了摄像头、麦克风、扬声器和网络",
  camera: "摄像头",
  microphone: "麦克风",
  speaker: "扬声器",
  net: "网络",
  tips: "请允许浏览器及网页访问以上设备",
  reConnect: "重新连接",
  startDetect: "开始检测",
  reDetect: "重新检测",
  cameraSelect: "摄像头切换",
  canSee: "看得见",
  canNotSee: "看不见",
  microphoneSelect: "麦克风切换",
  spakerSelect: "扬声器切换",
  canHear: "听得见",
  canNotHear: "听不见",
  volume: "音量",
  netConnect: "网络检测",
  system: "系统",
  broswer: "浏览器",
  TRTC: "视频监控",
  screenShare: "屏幕共享",
  Uplink: "上行网络质量",
  Downlink: "下行网络质量",
  Delay: "网络延时",
  Unknown: "未知",
  VeryGood: "极佳",
  Preferably: "较好",
  Commonly: "一般",
  Bad: "差",
  VeryBad: "极差",
  BreakOff: "断开",
  CheckReport: "查看报告",
  Normal: "正常",
  Abnormal: "异常",
  report: "设备检测报告",
  end: "下一步",
};
export default device;
