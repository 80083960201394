/*
 * @Author: Libra
 * @Date: 2022-04-19 10:31:03
 * @LastEditTime: 2022-05-07 16:32:23
 * @LastEditors: Libra
 * @Description: 英语
 * @FilePath: /stone-interview-ui/src/locales/en-US.ts
 */
import test from "./en/test";
import device from "./en/device";
import component from "./en/component";
import identity from "./en/identity";
export default {
  test,
  device,
  component,
  identity,
};
