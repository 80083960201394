/*
 * @Author: Libra
 * @Date: 2022-03-31 15:35:09
 * @LastEditTime: 2022-08-31 18:42:34
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/main.ts
 */
import { createApp } from "vue";
import router from "./route";
import App from "./App.vue";
import store from "./store";
import naive from "./ui/index";
import i18n from "./locales/index";
import VueClipboard from "vue3-clipboard";

const app = createApp(App);
app
  .use(router)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .use(store)
  .use(naive)
  .use(i18n)
  .mount("#app");
