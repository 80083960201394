/*
 * @Author: Libra
 * @Date: 2022-04-29 10:35:05
 * @LastEditTime: 2022-09-14 11:23:20
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/zh/component.ts
 */
const component = {
  header: {
    countdown: "面试已开始",
    logout: "退出",
    onlineInterview: "在线面试",
    tips: "提示",
    failureQR: "二维码失效",
    refresh: "请点击刷新",
    mobileAbnormal: "手机端视频监控异常断开",
    wechatReconnect: "请用微信扫码重连，15秒后状态恢复正常",
    wechatReconnectTips:
      "未开启视频监控，会影响考试结果。请关闭手机自动息屏，保持手机常亮！",
    know: "我知道了",
    recording: "录制中...",
    recordErr: "录制错误",
    didNotOpen: "未开启",
    isMonitoring: "正在监控",
    monitorErr: "监控异常",
    night: "黑夜模式",
    day: "白天模式",
    M: "管",
    ME: "主",
    E: "考",
    appoint: "预约时间：",
    QATitle: "常见问答",
  },
  footer: {
    content: "主办方：国考云｜本网站由国考云面试系统提供技术支持",
  },
  steps: {
    deviceDetect: "设备检测",
    basicInfo: "基本信息",
    identityVerify: "身份验证",
    mobileMonitor: "手机监控",
    interviewNotice: "面试须知",
  },
};
export default component;
