/*
 * @Author: Libra
 * @Date: 2022-05-07 16:30:53
 * @LastEditTime: 2022-09-08 09:46:51
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/en/identity.ts
 */
const identity = {
  authentication: "authentication",
  authTip: "Face recognition, please",
  name: "name",
  nameTip: "Please enter your name",
  identity: "identity",
  identityTip: "Please enter your identity number",
  mobile: "mobile",
  mobileTip: "Please enter your mobile number",
  email: "email",
  emailTip: "Please enter your email",
  resume: "resume",
  uploadResume: "upload resume",
  faceRecognition: "face recognition",
  next: "next",
  basicInfo: "basic info",
  basicTipCan:
    "The interview requires identity verification. Please fill in the information accurately",
  basicTipExa:
    "For confidentiality reasons, if the contact information is virtual, it can be ignored",
  result: "result",
  success: "success",
  fail: "fail",
  pre: "pre",
  snap: "snap",
  reason: "reason",
  remain: "remain",
  time: "times",
  skip: "skip",
  wechatScan: "wechat scan",
  getVerifyResult: "get verify result",
  mobileDetect: "mobile detect",
  mobileDetectTip: "Scan the QR code to monitor the mobile",
  mobileWechatScan: "wechat scan",
  putPhone: "Set up mobile",
  startDetect: "start detect",
  scanFault: "scan fault?",
  putPhoneRight: "put phone on the right side of the computer",
  confirmRecord: "confirm record",
  scanAndNext: "scanned, next",
  faultTip:
    "What should I do if I encounter a problem with scanning the code? <br />1. Please check whether the network connection of your mobile phone is normal;<br />2. Please update the WeChat APP of your mobile phone to the latest version, and then scan the code again; <br />3. program, do not jump out of the monitoring page, it is recommended to plug in the power of the mobile phone.",
  interviewNotice: "interview notice",
  interviewNoticeTip: "interview notice as follows",
  agree: "I agree with the above content",
  startInterview: "start interview",
  qrFailure: "QR code failure",
  refresh: "refresh",
  useCamera: "use camera",
  openCamera:
    "Click the upper right corner of the browser to enable the camera",
  refreshPage: "The page refresh takes effect only after the device is enabled",
  know: "I know",
  cameraSnap: "camera snap",
  cameraSnapTip:
    "Please take a selfie. All your personal information will be kept strictly confidential",
  reSnap: "back to snap",
  snapAndNext: "snap and next",
  goSnap: "snap",
  enterWaitingRoom: "enter waiting room",
  enterInterviewRoom: "enter interview room",
};
export default identity;
