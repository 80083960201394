/*
 * @Author: Libra
 * @Date: 2022-04-29 10:36:11
 * @LastEditTime: 2022-09-14 11:23:28
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/en/component.ts
 */
const component = {
  header: {
    countdown: "countdown",
    logout: "logout",
    onlineInterview: "Online Video Interview",
    tips: "tips",
    failureQR: "failure QR",
    refresh: "please click refresh",
    mobileAbnormal: "mobile video monitoring abnormal disconnection",
    wechatReconnect:
      "please use wechat to scan the QR code to reconnect, and the status will be restored to normal after 15 seconds",
    wechatReconnectTips:
      "video monitoring is not turned on, which will affect the exam results. Please turn off the automatic screen off function of the mobile phone and keep the mobile phone bright!",
    know: "I know",
    recording: "recording...",
    recordErr: "record error",
    didNotOpen: "did not open",
    isMonitoring: "is monitoring",
    monitorErr: "monitor error",
    night: "night mode",
    day: "day mode",
    M: "M",
    ME: "ME",
    E: "E",
    appoint: "appoint time:",
    QATitle: "Q&A",
  },
  footer: {
    content:
      "sponsor：guokaoyun｜This website is supported by guokaoyun exam system",
  },
  steps: {
    deviceDetect: "device detection",
    basicInfo: "basic info",
    identityVerify: "identity verification",
    mobileMonitor: "mobile monitor",
    interviewNotice: "interview notice",
  },
};
export default component;
