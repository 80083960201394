/*
 * @Author: Libra
 * @Date: 2022-04-19 10:48:06
 * @LastEditTime: 2022-10-19 14:44:30
 * @LastEditors: Libra
 * @Description:黑暗主题
 * @FilePath: /stone-interview-ui/src/ui/theme/dark.ts
 */
import { GlobalThemeOverrides } from "naive-ui";
const darkThemeOverrides: GlobalThemeOverrides = {
  common: {
    primaryColor: "#f35a5a",
    primaryColorHover: "rgba(243, 90, 90, 0.8)",
    primaryColorPressed: "rgba(243, 90, 90, 0.8)",
  },
  Steps: {
    // 获取css变量
    indicatorTextColorProcess: "#ffffff",
    indicatorTextColorWait: "#acacac",
    headerTextColorWait: "#acacac",
    headerTextColorProcess: "#f35a5a",
    descriptionTextColorWait: "#000",
    stepHeaderFontSizeMedium: "14px",
    indicatorSizeMedium: "35px",
    indicatorBorderColorWait: "#acacac",
    splitorColorWait: "#acacac",
    splitorColorProcess: "#f35a5a",
    indicatorColorFinish: "#f35a5a",
    indicatorTextColorFinish: "#ffffff",
    headerTextColorFinish: "#f35a5a",
  },
  Select: {
    peers: {
      InternalSelection: {
        textColor: "#ffffff",
        border: "none",
        borderHover: "none",
        borderActive: "none",
        borderFocus: "none",
        boxShadowActive: "none",
        boxShadowFocus: "none",
        boxShadowHover: "none",
        color: "rgba(243, 90, 90, 0.1)",
        colorActive: "rgba(243, 90, 90, 0.1)",
        arrowColor: "#ffffff",
        borderRadius: "4px",
        heightMedium: "40px",
      },
    },
  },
  Form: {
    labelFontSizeLeftLarge: "14px",
    feedbackTextColorError: "#d0413a",
  },
  Input: {
    color: "rgba(243, 90, 90, 0.1)",
    border: "none",
    placeholderColor: "#acacac",
    textColor: "#ffffff",
    colorFocus: "rgba(243, 90, 90, 0.1)",
    borderRadius: "4px",
  },
  Button: {
    textColorPrimary: "#ffffff",
    textColorHoverPrimary: "#ffffff",
    textColorPressedPrimary: "#ffffff",
    textColorDisabledPrimary: "#ffffff",
    textColorFocusPrimary: "#ffffff",
  },
  Slider: {
    handleSize: "0px",
    fillColor: "#5fa4f9",
    fillColorHover: "#5fa4f9",
  },
  Tabs: {
    tabTextColorActiveSegment: "#f35a5a",
    tabColorSegment: "rgba(243, 90, 90, 0.2)",
  },
};
export default darkThemeOverrides;
