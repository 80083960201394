<!--
 * @Author: Libra
 * @Date: 2022-04-06 14:54:57
 * @LastEditTime: 2022-04-19 11:54:52
 * @LastEditors: Libra
 * @Description: 
 * @FilePath: /stone-interview-ui/src/components/Message.vue
-->
<template>
  <div></div>
</template>

<script setup lang="ts">
import { useMessage } from "naive-ui";
window.$message = useMessage();
</script>

<style scoped></style>
