<!--
 * @Author: Libra
 * @Date: 2022-03-31 15:35:09
 * @LastEditTime: 2022-09-20 14:55:40
 * @LastEditors: Libra
 * @Description: App.vue
 * @FilePath: /stone-interview-ui/src/App.vue
-->
<script setup lang="ts">
import { lightThemeOverrides, darkThemeOverrides } from "./ui/theme";
import { Message } from "./components/Message";
import { NMessageProvider, useMessage } from "naive-ui";
import { darkTheme } from "naive-ui";
import { computed, ref, watch } from "vue";
import { ThemeStore } from "./store/modules/theme";
import { useRoute } from "vue-router";

/**
 * 主题相关
 */
const themeStore = ThemeStore();
const theme = computed(() =>
  themeStore.theme === "dark" ? darkTheme : undefined
);

/**
 * 路由过度动画
 */
const transition = ["slide-left", "slide-right"];
let transitionName = ref(transition[0]);
const route = useRoute();
watch(
  () => route.meta.index as Number,
  (newIndex, oldIndex) => {
    transitionName.value = newIndex > oldIndex ? transition[0] : transition[1];
  }
);
</script>

<template>
  <n-config-provider
    :theme-overrides="theme ? darkThemeOverrides : lightThemeOverrides"
    :theme="theme"
  >
    <n-message-provider>
      <Message />
    </n-message-provider>
    <router-view v-slot="{ Component }">
      <transition :name="transitionName">
        <component :is="Component" />
      </transition>
    </router-view>
  </n-config-provider>
</template>

<style lang="scss">
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-active,
.slide-right-leave-active {
  width: 100%;
  height: 100%;
  will-change: transform;
  transition: all 300ms cubic-bezier(0.55, 0, 0.1, 1);
  position: absolute;
  backface-visibility: hidden;
}
.slide-right-enter-active {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}
.slide-right-leave-active {
  opacity: 0;
  transform: translate3d(3%, 0, 0);
}
.slide-left-enter-active {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}
.slide-left-leave-active {
  opacity: 0;
  transform: translate3d(-3%, 0, 0);
}
</style>
