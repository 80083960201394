/*
 * @Author: Libra
 * @Date: 2022-04-01 15:57:16
 * @LastEditTime: 2022-11-27 20:45:28
 * @LastEditors: Libra
 * @Description: vue-router 4
 * @FilePath: /stone-interview-ui/src/route/index.ts
 */
import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
  RouteRecordRaw,
  Router,
} from "vue-router";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/detect",
  },
  {
    path: "/detect",
    name: "Detect",
    component: () => import("@views/prepare/detect/index.vue"),
    meta: { index: 0 },
  },
  {
    path: "/basic",
    name: "Basic",
    component: () => import("@views/prepare/basic/index.vue"),
    meta: { index: 1 },
  },
  {
    path: "/identity",
    name: "Identity",
    component: () => import("@views/prepare/identity/index.vue"),
    meta: { index: 2 },
  },
  {
    path: "/snap",
    name: "Snap",
    component: () => import("@views/prepare/snap/index.vue"),
    meta: { index: 2 },
  },
  {
    path: "/mobile",
    name: "Mobile",
    component: () => import("@views/prepare/mobile/index.vue"),
    meta: { index: 3 },
  },
  {
    path: "/notice",
    name: "Notice",
    component: () => import("@views/prepare/notice/index.vue"),
    meta: { index: 4 },
  },
  {
    path: "/interview",
    name: "Interview",
    component: () => import("@views/interview/index.vue"),
    meta: { index: 5 },
  },
  {
    path: "/mark",
    name: "Mark",
    component: () => import("@views/mark/index.vue"),
    meta: { index: 6 },
  },
  {
    path: "/finish",
    name: "Finish",
    component: () => import("@views/finish/index.vue"),
    meta: { index: 7 },
  },
  {
    path: "/confirm",
    name: "Confirm",
    component: () => import("@views/invite/index.vue"),
    children: [
      {
        path: "success",
        name: "Success",
        component: () => import("@views/invite/Success.vue"),
      },
      {
        path: "consider",
        name: "Consider",
        component: () => import("@views/invite/Consider.vue"),
      },
      {
        path: "refuse",
        name: "Refuse",
        component: () => import("@views/invite/Refuse.vue"),
      },
      {
        path: "message",
        name: "message",
        component: () => import("@views/invite/message.vue"),
      },
    ],
  },
  // {
  //   path: "/test",
  //   name: "Test",
  //   component: () => import("@views/test/index.vue"),
  //   meta: { index: 8 },
  // },
  {
    path: "/qa",
    name: "qa",
    component: () => import("@views/qa/index.vue"),
    meta: { index: 9 },
  },
  {
    path: "/test",
    name: "test",
    component: () => import("@views/test.vue"),
    meta: { index: 10 },
  },
];

const router: Router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
