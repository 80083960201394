/*
 * @Author: Libra
 * @Date: 2022-04-24 16:11:56
 * @LastEditTime: 2022-09-07 15:15:08
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/en/device.ts
 */
const device = {
  deviceConnect: "device connect",
  cameraDetect: "camera detect",
  microphoneDetect: "microphone detect",
  speakerDetect: "speaker detect",
  speakerTips: "Please click the play button above to open the computer sound",
  camerahint: "is it clear to see you?",
  sayHello: "say hello to microphone ~",
  microphonehint: "is it clear to see the volume icon?",
  subtitle:
    "please confirm the device is connected to the camera, microphone, speaker and network",
  camera: "camera",
  microphone: "microphone",
  speaker: "speaker",
  net: "net",
  tips: "Please allow browsers and web pages to access the above devices",
  reConnect: "check back",
  startDetect: "start detect",
  reDetect: "detect back",
  cameraSelect: "Camera",
  canSee: "yes",
  canNotSee: "no",
  microphoneSelect: "Microphone",
  spakerSelect: "Speaker",
  canHear: "yes",
  canNotHear: "no",
  volume: "volume",
  netConnect: "network detect",
  system: "system",
  broswer: "browser",
  TRTC: "TRTC",
  screenShare: "screen sharing",
  Uplink: "Uplink network quality",
  Downlink: "Downlink network quality",
  Delay: "Network Delay",
  Unknown: "Unknown",
  VeryGood: "Very good",
  Preferably: "Preferably",
  Commonly: "Commonly",
  Bad: "Bad",
  VeryBad: "Very bad",
  BreakOff: "Break off",
  CheckReport: "Check report",
  Normal: "Normal",
  Abnormal: "Abnormal",
  report: "detect report",
  end: "next",
};
export default device;
