/*
 * @Author: Libra
 * @Date: 2022-04-19 10:53:10
 * @LastEditTime: 2022-04-24 15:44:32
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/zh/test.ts
 */
const test = {
  test2: {
    testL: "语言",
    home: "去主页",
    detect: "设备检测",
    theme: "主题",
    avatar: "头像",
  },
};
export default test;
