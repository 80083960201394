/*
 * @Author: Libra
 * @Date: 2022-04-19 15:07:02
 * @LastEditTime: 2022-04-19 15:34:59
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/store/modules/theme.ts
 */
import { defineStore } from "pinia";

export const ThemeStore = defineStore("theme", {
  state: () => ({ theme: "light" }),
  actions: {
    setTheme(theme: string) {
      this.theme = theme;
    },
  },
  getters: {
    getTheme: (state) => state.theme,
  },
  persist: {
    enabled: true,
  },
});
