/*
 * @Author: Libra
 * @Date: 2022-04-19 10:53:05
 * @LastEditTime: 2022-04-24 15:45:42
 * @LastEditors: Libra
 * @Description:
 * @FilePath: /stone-interview-ui/src/locales/en/test.ts
 */
const test = {
  test2: {
    testL: "language",
    home: "go to home",
    detect: "device detection",
    theme: "theme",
    avatar: "avatar",
  },
};
export default test;
